@import "variables";

$body-bg: #000;
$body-color: #999;
$font-family-base: "Inter", sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600;800&display=swap');

@import "~bootstrap/scss/bootstrap";

@import "components/index";
@import "pages/index";

body {
    //
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 400;
    color: #fff;
}

a {
    color: $bitcoin-orange;
    text-decoration: none;

    &:hover {
        color: $bitcoin-orange;
        text-decoration: underline;
        text-shadow: 0 0 5px transparentize($bitcoin-orange, .5);
    }
}

p {
    &:last-child {
        margin-bottom: 0;
    }
}

::selection {
    color: #000;
    background: $bitcoin-orange;
}
