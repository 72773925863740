.collection-list {
    .collection {
        display: block;
        width: 100%;
        padding-top: 50%;
        position: relative;
        background: transparentize($bitcoin-orange, .9);
        text-decoration: none;
        border: 1px solid rgba(255, 255, 255, .25);
        margin-bottom: var(--bs-gutter-x);
        overflow: hidden;

        &:hover {
            text-shadow: 0 0 10px rgba(0,0,0,.4);
            background: transparentize($bitcoin-orange, .75);
            box-shadow: 0 0 10px -5px rgba(255, 255, 255, .75);
        }

        > .bg {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            transform: translateY(-50%);
            opacity: .2;

            img {
                width: 100%;
            }
        }

        .content {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);

            .collection-title {
                font-size: 1.5rem;
                display: block;
                margin-bottom: .25rem;
            }

            .items-count {
                display: block;
            }
        }
    }
}
