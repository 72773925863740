header.explore {
    img.logo {
        filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
        opacity: .5;
    }
}

.rarity-filter {
    .categories {
        a.rarity {
            width: 100%;
            background: transparentize($bitcoin-orange, .9);
            text-decoration: none;
            display: block;
            padding-top: 50%;
            border: 1px solid rgba(255, 255, 255, .25);
            position: relative;
            margin-bottom: var(--bs-gutter-x);

            &:hover {
                text-shadow: 0 0 10px rgba(0,0,0,.4);
                background: transparentize($bitcoin-orange, .75);
                box-shadow: 0 0 10px -5px rgba(255, 255, 255, .75);
            }

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
