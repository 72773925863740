.navbar {
    .navbar-brand {
        text-decoration: none !important;
    }

    .nav-link {
        &:hover {
            color: #fff;
            text-decoration: none !important;
            text-shadow: 0 0 10px rgba(255, 255, 255, .25);
        }
    }
}
